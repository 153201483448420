
.main-area { 
    background-color: transparent; 
    overflow: hidden; 
    width: 100%; 
    overflow-x: hidden; 
    overflow-y: hidden;
} 

.center_xy { 
    align-items: center; 
    display: flex; 
    flex-direction: column;
} 

.progress { 
    height: 1rem; 
    line-height: 0; 
    font-size: 0.75rem; 
    background-color: rgb(233, 236, 239); 
    border-radius: .25rem; 
    border-top-left-radius: 0.25rem; 
    border-top-right-radius: 0.25rem; 
    border-bottom-right-radius: 0.25rem; 
    border-bottom-left-radius: 0.25rem;
    display: flex; 
    overflow: hidden; 
    overflow-x: hidden; 
    overflow-y: hidden;
} 

.progress_bar { 
    display: flex; 
    overflow: hidden; 
    overflow-x: hidden; 
    overflow-y: hidden;
    flex-direction: column; 
    justify-content: center; 
    color: rgb(255, 255, 255); 
    text-align: center; 
    white-space: nowrap; 
    background-color: rgb(0, 123, 255); 
    transition: width .6s ease; 
    transition-duration: 0.6s; 
    transition-timing-function: ease; 
    transition-delay: 0s; 
    transition-property: width;
} 

.progress_bar_striped { 
    background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 0px, transparent 50%, rgba(255, 255, 255, 0.15) 0px, rgba(255, 255, 255, 0.15) 75%, transparent 0px, transparent); 
    background-size: 1rem 1rem;
}

.bg_success { 
    background-color: rgb(40, 167, 69) !important;
} 

.refer_footer { 
    position: absolute;
    align-items: center; 
    background-color: rgb(250, 250, 250); 
    border-top: 1px solid #e0e0e0; 
    bottom: 0px; 
    display: flex; 
    height: 73px; 
    left: 0px; 
    max-width: 480px; 
    padding: 0 30px; 
    /* position: fixed;  */
    width: 100%; 
    z-index: 3; 
    border-top-width: 1px; 
    border-top-style: solid; 
    border-top-color: rgb(224, 224, 224); 
    padding-top: 0px; 
    padding-right: 30px; 
    padding-bottom: 0px; 
    padding-left: 30px;
} 

.font_11 {
    color: #2c2c2c;
    font-size: 1.1em;
    font-weight: 900;
}

.text_bold {
    color: #2c2c2c;
    font-size: 1em;
    font-weight: 800;
}
.font_9{
    color: #2c2c2c !important;
    font-weight: 700 !important;
    font-size: 0.9em  !important;

}
.font_8{
    font-weight: 500 !important;
    font-size: 0.8em  !important;

}
.c_green{
    color: #0db25b !important;
}