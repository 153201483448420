.footer2 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding: 10px 0;
  background: #ffcccb; /* Example gradient from dark blue to lighter blue */
  border-top: 1px solid aqua;
  position: fixed;
  bottom: 0;
  max-width: 480px;
  width: 100%;
}

.footer-link {
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  color: white;
  font-size: 18px;
}
.homel {
  font-size: 22px;
  font-weight: bolder;
}
